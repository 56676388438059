import Vue from 'vue';
import VueRouter from 'vue-router';

const SlidesLayout = () => import('@/components/Slides/SlidesLayout.vue');
const SlidesTransitionWrapper = () => import('@/components/Slides/SlidesTransitionWrapper.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Wrapper',
    component: SlidesTransitionWrapper,
    redirect: '/decks/default/1',
    children: [
      {
        path: '/decks/:deck/:id',
        name: 'Slides',
        component: SlidesLayout,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.query.url && !to.query.url) {
    const toWithQuery = { ...to, query: from.query };
    next(toWithQuery);
  } else {
    next();
  }
});

export default router;
