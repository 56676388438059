<template>
  <div v-if="imageLoaded && !hasRequestFailed"
    :style="{
      backgroundImage: `url('${image}')`,
      backgroundSize: `${slide.image.backgroundSize}`}"
    class="full-size-image"/>
  <placeholder v-else-if="hasRequestFailed" />
</template>

<script>
import axios from 'axios';
import Placeholder from '@/components/SlideTemplates/basic/Placeholder.vue';

export default {
  name: 'FullSlideImage',
  components: {
    Placeholder,
  },
  props: {
    slide: Object,
  },
  computed: {
    imageLoaded() {
      return this.image != null;
    },
  },
  data() {
    return {
      image: null,
      hasRequestFailed: false,
    };
  },
  mounted() {
    this.fetchImage();
  },
  methods: {
    fetchImage() {
      axios.get(this.slide.image.url, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 204) {
            this.hasRequestFailed = true;
          } else {
            this.image = window.URL.createObjectURL(response.data);
          }
        })
        .catch(() => {
          this.hasRequestFailed = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.full-size-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
}

</style>
