<template>
  <div class="error-message">
    We are missing data to display this slide, complete more tasks in order to make it available.
  </div>
</template>

<script>
export default {
  name: 'Placeholder',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
}

</style>
