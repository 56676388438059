/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    decks: new Map(),
    jwt: null,
  },
  mutations: {
    ADD_SLIDE_DECK: (state, deck) => {
      state.decks.set(deck.deckUrlEncoded, deck.definition);
    },
    SET_JWT: (state, jwt) => {
      state.jwt = jwt;
    },
  },
  getters: {
    getDeckByUrlEncoded: (state) => (urlEncoded) => state.decks.get(urlEncoded),
    getJWT: (state) => state.jwt,
  },
  actions: {
    fetchDeck(context, { deckUrlEncoded, jwtRelayUrl }) {
      // if we come from another application, such as Avalia Assess, we may have been passed
      // a URL where we can fetch a JWT token for the user (SSO)
      function sso() {
        if (jwtRelayUrl) {
          return axios.get(jwtRelayUrl)
            .then((responseWithJwt) => {
              axios.defaults.headers.common.Authorization = `Bearer ${responseWithJwt.data}`;
              context.commit('SET_JWT', responseWithJwt.data);
              // store somewhere else so i can feed it to vega
              return true;
            });
        }
        return new Promise((resolve) => { resolve(true); });
      }

      return sso().then(() => axios
        .get(decodeURIComponent(deckUrlEncoded))
        .then((response) => {
          response.data.slides = response.data.slides.filter((slide) => !slide.hidden);
          context.commit('ADD_SLIDE_DECK', {
            deckUrlEncoded,
            definition: response.data,
          });
          return response.data;
        }));
    },
  },
});

export default store;
